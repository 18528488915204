import { PureComponent, ReactNode } from "react";
import nftmarketplace from "../../assets/images/nft-marketplace-image.svg";
import "./nft_marketplace.css";

export class NftMarketplace extends PureComponent {
    public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#nft-marketplace-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));
    }

    public render(): ReactNode {
        return (
            <div id="nft-marketplace-row-background">
                <div className="container">
                    <section id="nft-marketplace-row">
                        <div id="nft-marketplace-image-col" className="hidden">
                            <img src={nftmarketplace} alt=""/>
                        </div>
                        <div id="nft-marketplace-description-col" className="hidden">
                            <h2 className="rgb-white">NFT Marketplace</h2>
                            <p className="rgb-light-grey">Ready to own a piece of collaborative art history? The completed artworks will be available on <a className="rarible-yellow" href="https://rarible.com/rgblisk/sale" style={{ textDecoration: "none" }}>Rarible</a>, one of Lisk's leading NFT marketplaces.</p>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
