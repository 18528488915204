import { Component, ReactNode } from "react";
import Lottie from "react-lottie-player";
import create from "../../assets/video/create-image.json";
import "./create.css";

interface CreateState {
    play: boolean;
}

export class Create extends Component<{}, CreateState> {
    public state: CreateState = {
        play: false,
    };

    private hiddenObserver: IntersectionObserver | null = null;
    private playObserver: IntersectionObserver | null = null;

    public componentDidMount(): void {
        this.hiddenObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#create-row .hidden");
        hiddenElements.forEach((elem) => this.hiddenObserver?.observe(elem));

        this.playObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                this.setState((state) => ({ ...state, play: entry.isIntersecting }));
            });
        });
        const playElements = document.querySelectorAll("#create-image-col");
        playElements.forEach((elem) => this.playObserver?.observe(elem));
    }

    public componentWillUnmount(): void {
        if (this.hiddenObserver !== null) {
            this.hiddenObserver.disconnect();
        }

        if (this.playObserver !== null) {
            this.playObserver.disconnect();
        }
    }

    public render(): ReactNode {
        return (
            <div className="container" id="learn">
                <section id="create-row">
                    <div id="create-description-col" className="hidden">
                        <h2 className="rgb-green">Create</h2>
                        <p>Make your mark on a <b>unique timelapse NFT</b>. Every brushstroke you create is captured in real-time and stored on our blockchain. The final piece is a dynamic digital artwork that reveals its own story of creation!</p>
                    </div>
                    <div id="create-image-col" className="hidden">
                        <Lottie loop animationData={create} play={this.state.play} />
                    </div>
                </section>
            </div>
        );
    }
}
