import { Component, ReactNode } from "react";
import Lottie from "react-lottie-player";
import win from "../../assets/video/win-image.json";
import "./win.css";

interface WinState {
    play: boolean;
}

export class Win extends Component<{}, WinState> {
    public state: WinState = {
        play: false,
    };

    private hiddenObserver: IntersectionObserver|null = null;
    private playObserver: IntersectionObserver|null = null;

    public componentDidMount(): void {
        this.hiddenObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#win-row .hidden");
        hiddenElements.forEach((elem) => this.hiddenObserver?.observe(elem));

        this.playObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                this.setState((state) => ({ ...state, play: entry.isIntersecting }));
            });
        });
        const playElements = document.querySelectorAll("#win-image-col");
        playElements.forEach((elem) => this.playObserver?.observe(elem));
    }

    public componentWillUnmount(): void {
        if (this.hiddenObserver !== null) {
            this.hiddenObserver.disconnect();
        }

        if (this.playObserver !== null) {
            this.playObserver.disconnect();
        }
    }

    public render(): ReactNode {
        return (
            <div id="win-row-background">
                <div className="container">
                    <section id="win-row">
                        <div id="win-description-col" className="hidden">
                            <h2 className="rgb-white">WIN</h2>
                            <p className="rgb-light-grey">Not only will you be an NFT creator but you will also have the opportunity to win <strong>BIG</strong>. Each NFT acts as a pool where tokens are distributed to the lucky winners!</p>
                        </div>
                        <div id="win-image-col" className="hidden">
                            <Lottie loop animationData={win} play={this.state.play} />
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
