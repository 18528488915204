import { Component, ReactNode } from "react";
import { ChevronDown } from "lucide-react";

interface RotatingChevronDownProps {
    onClick?: (isRotated: boolean) => void;
}

interface RotatingChevronDownState {
    isRotated: boolean;
}

export class RotatingChevronDown extends Component<RotatingChevronDownProps, RotatingChevronDownState> {
    public state: RotatingChevronDownState = {
        isRotated: false,
    };

    public render(): ReactNode {
        const chevronStyle = {
            transition: "transform 0.3s ease",
            transform: this.state.isRotated ? "rotate(-90deg)" : "rotate(0deg)",
        };
        return (
            <button
                onClick={() => {
                    const isRotated = !this.state.isRotated;
                    this.setState({ isRotated: isRotated });
                    this.props.onClick?.(isRotated);
                }}
                style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    margin: 0,
                    cursor: "pointer",
                    width: 24,
                    height: 24,
                }}
            >
                <ChevronDown
                    style={chevronStyle}
                    size={24}
                    color="#dadde1"
                />
            </button>
        );
    }
}
