import { Component, Fragment, ReactNode } from "react";
import { Link } from "react-scroll";
import background from "../../assets/images/header-background.svg";
import headerLarge from "../../assets/images/header-image.svg";
import headerSmall from "../../assets/images/header-image-mobile.png";
import { linearInterpolateY } from "../../components/util/helpers";
import "./header.css";

interface HeaderState {
    headerTitleLeft: string;
}

export class Header extends Component<{}, HeaderState> {
    public state: HeaderState  = {
        headerTitleLeft: "",
    };
    private handleResize: () => void = () => {
        const screenWidth = window.innerWidth;
        this.setState((state) => ({
            ...state,
            headerTitleLeft: this.calculateHeaderTitleLeft(screenWidth),
        }));
    };

    public componentDidMount(): void {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.4 });
        const hiddenElements = document.querySelectorAll("#header-row .hidden");
        hiddenElements.forEach((elem) => observer.observe(elem));

        window.addEventListener("resize", this.handleResize);
        // Initialise component scaling
        this.handleResize();
    }

    public componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    public render(): ReactNode {
        return (
            <Fragment>
                <img id="header-row-background" src={background} alt=""/>
                <div className="container">
                    <section id="header-row">
                        <div id="header-description-col" className="hidden">
                            <h3 className="rgb-blue">World First NFT Collaboration Platform</h3>
                            <h1 id="header-title" className="rgb-black">For
                                <div style={{ left: this.state.headerTitleLeft, }}>Artists</div>
                                <div style={{ left: this.state.headerTitleLeft, }}>Creators</div>
                                <div style={{ left: this.state.headerTitleLeft, }}>Traders</div>
                                <div style={{ left: this.state.headerTitleLeft, }}>Enthusiasts</div>
                                <div style={{ left: this.state.headerTitleLeft, }}>Newcomers</div>
                                <div style={{ left: this.state.headerTitleLeft,  textDecoration: "underline" }}>Everyone!</div>
                            </h1>
                            <p className="rgb-black">Join us as we create history by capturing internet culture in <b>NFT</b> format using the <b>RGB</b> platform powered by Lisk.</p>
                            <Link
                                className="rgb-button-blue"
                                to="learn"
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >Learn More</Link>
                        </div>
                        <div id="header-image-col-lg" className="hidden">
                            <img src={headerLarge} alt=""/>
                        </div>
                        <div id="header-image-col-sm" className="hidden">
                            <img src={headerSmall} alt=""/>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }

    private calculateHeaderTitleLeft(screenWidth: number): string {
        if (screenWidth > 798) {
            return "125px";
        }

        return linearInterpolateY(screenWidth, {x: 492, y: 76}, {x: 798, y: 125}) + "px";
    }
}
