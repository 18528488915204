import { Component, ReactNode } from "react";
import Lottie from "react-lottie-player";
import earn from "../../assets/video/earn-image.json";
import "./earn.css";

interface EarnState {
    play: boolean;
}

export class Earn extends Component<{}, EarnState> {
    public state: EarnState = {
        play: false,
    };

    private hiddenObserver: IntersectionObserver | null = null;
    private playObserver: IntersectionObserver | null = null;

    public componentDidMount(): void {
        this.hiddenObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !entry.target.classList.contains("show")) {
                    entry.target.classList.add("show");
                }
            });
        }, { threshold: 0.8 });
        const hiddenElements = document.querySelectorAll("#earn-row .hidden");
        hiddenElements.forEach((elem) => this.hiddenObserver?.observe(elem));

        this.playObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                this.setState((state) => ({ ...state, play: entry.isIntersecting }));
            });
        });
        const playElements = document.querySelectorAll("#earn-image-col");
        playElements.forEach((elem) => this.playObserver?.observe(elem));
    }

    public componentWillUnmount(): void {
        if (this.hiddenObserver !== null) {
            this.hiddenObserver.disconnect();
        }

        if (this.playObserver !== null) {
            this.playObserver.disconnect();
        }
    }

    public render(): ReactNode {
        return (
            <div className="container">
                <section id="earn-row">
                    <div id="earn-description-col" className="hidden">
                        <h2 className="rgb-pink">Earn</h2>
                        <p>Draw as much or as little as you want - every pixel counts towards your share of the proceeds when the artwork sells. <b>We'll take care of the rest!</b> From running the NFT auction to automatically distributing profits based on your contribution. Just grab your digital brush and start creating!</p>
                    </div>
                    <div id="earn-image-col" className="hidden">
                        <Lottie loop animationData={earn} play={this.state.play} />
                    </div>
                </section>
            </div>
        );
    }
}
