import { Mutex } from "async-mutex";
import { Component, Fragment, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import lineBackground from "../../assets/images/pending-line-background.svg";
import yellowBackground from "../../assets/images/pending-yellow-background.svg";
import { ApiClient } from "../../components/clients/api_client";
import { NavBar } from "../../components/layout/navbar";
import { Spinner } from "../../components/loading/spinner";
import { CANVAS_ID_KEY } from "../../components/util/common";
import "./pending.css";

interface RemainingTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

interface PendingState {
    redirect: string|null;
    canvasId: number|null;
    blockTime: number|null;
    timestamp: number|null;
    remaining: RemainingTime|null;
    canvasStartedId: string|null;
    blockNewId: string|null;
}

export class Pending extends Component<any, PendingState> {
    state: PendingState = {
        redirect: null,
        canvasId: null,
        blockTime: null,
        timestamp: null,
        remaining: null,
        canvasStartedId: null,
        blockNewId: null,
    };
    private timer: NodeJS.Timeout|null = null;
    private lock: Mutex = new Mutex();

    public async componentDidMount(): Promise<void> {
        await this.lock.runExclusive(async() => {
            const searchParams: URLSearchParams = new URLSearchParams(window.location.search);

            if (!searchParams.has(CANVAS_ID_KEY)) {
                this.redirectHome();
                return;
            }

            const canvasId = searchParams.get(CANVAS_ID_KEY);

            if (canvasId === null) {
                this.redirectHome();
                return;
            }

            const canvasIdFormatted = Number(canvasId);

            const canvas = await ApiClient.getInstance().queryCanvas(canvasIdFormatted);

            if (canvas === null) {
                this.redirectHome();
                return;
            }

            const canvasStartedId = await ApiClient.getInstance().subscribeCanvasStarted((canvasId) => {
                if (canvasId === canvasIdFormatted) {
                    this.redirectDraw(canvasIdFormatted);
                }
            });
            const blockNewId = await ApiClient.getInstance().subscribeBlockNew((block) => {
                if (this.state.blockTime && block) {
                    this.setState({
                        ...this.state,
                        timestamp: this.calculateTimestamp(
                            block.timestamp,
                            block.number,
                            canvas.blockRange.start,
                            this.state.blockTime
                        ),
                    });
                }
            });

            const nodeInfo = await ApiClient.getInstance().queryNodeInfo();
            const lastBlock = await ApiClient.getInstance().queryLastBlock();
            const timestamp = this.calculateTimestamp(
                lastBlock.timestamp,
                lastBlock.number,
                canvas.blockRange.start,
                nodeInfo.blockTime
            );

            this.timer = setInterval(() => {
                const remainingTime = this.calculateRemainingTime(this.state.timestamp ?? timestamp);

                if (this.isElapsed(remainingTime)) {
                    // Might have missed the canvas:started event so redirecting anyway because will check if canvas has
                    // started or not
                    this.redirectDraw(canvasIdFormatted);
                } else {
                    this.setState({ ...this.state, remaining: remainingTime });
                }
            }, 1000);

            const remainingTime = this.calculateRemainingTime(timestamp);
            this.setState({
                ...this.state,
                canvasId: canvasIdFormatted,
                blockTime: nodeInfo.blockTime,
                timestamp: timestamp,
                remaining: remainingTime,
                canvasStartedId: canvasStartedId,
                blockNewId: blockNewId,
            });
        });
    }

    public async componentWillUnmount(): Promise<void> {
        await this.lock.runExclusive(async () => {
            document.body.style.background = "";

            if (this.timer !== null) {
                clearInterval(this.timer);
            }

            if (this.state.canvasStartedId) {
                await ApiClient.getInstance().unsubscribeCanvasStarted(this.state.canvasStartedId);
            }

            if (this.state.blockNewId) {
                await ApiClient.getInstance().unsubscribeBlockNew(this.state.blockNewId);
            }
        });
    }

    public render(): ReactNode {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />;
        }

        document.body.style.background = "rgba(226, 226, 255, 0.4)";

        return (
            <Fragment>
                <NavBar floating={false} redirect={true} />
                <img src={lineBackground} alt="#" id="pending-line-background"/>
                <img src={yellowBackground} alt="#" id="pending-yellow-background"/>
                <div id="pending-container">
                    <div>
                        <h2>Starting in</h2>
                        {" "}
                        {this.getRemaining()}
                    </div>
                    <div>
                        <p>RGB platform powered by Lisk</p>
                    </div>
                </div>
            </Fragment>
        );
    }

    private getRemaining(): ReactNode {
        if (!this.state.remaining) {
            return (
                <div id="timer-container">
                    <Spinner colour="white" width={14} height={14} />
                </div>
            );
        }

        return (
            <div id="timer-container" className="timer-container-loaded">
                {(this.state.remaining.days === 0) ? null :
                    <div>
                        {" "}
                        <span>{this.state.remaining.days}</span>
                        <span>{(this.state.remaining.days !== 1) ? "days" : "day"}</span>
                    </div>
                }
                {(this.state.remaining.days === 0 && this.state.remaining.hours === 0) ? null :
                    <div>
                        {" "}
                        <span>{this.state.remaining.hours}</span>
                        <span>{(this.state.remaining.hours !== 1) ? "hours" : "hour"}</span>
                    </div>
                }
                {(this.state.remaining.days !== 0) ? null :
                    <div>
                        {" "}
                        <span>{this.state.remaining.minutes}</span>
                        <span>{(this.state.remaining.minutes !== 1) ? "minutes" : "minute"}</span>
                    </div>
                }
                {(this.state.remaining.days !== 0 || this.state.remaining.hours !== 0) ? null :
                    <div>
                        {" "}
                        <span>{this.state.remaining.seconds}</span>
                        <span>{(this.state.remaining.seconds !== 1) ? "seconds" : "second"}</span>
                    </div>
                }
            </div>
        );
    }

    private redirectHome(): void {
        this.setState({ ...this.state, redirect: "/" });
    }

    private redirectDraw(canvasId: number): void {
        this.setState({ ...this.state, canvasId: canvasId, redirect: `/draw?${CANVAS_ID_KEY}=${this.state.canvasId}` });
    }

    private calculateRemainingTime(timestamp: number): RemainingTime {
        const total = timestamp - Math.floor(Date.now() / 1000);
        const days = Math.max(Math.floor(total / (60 * 60 * 24)), 0);
        const hours = Math.max(Math.floor((total / (60 * 60)) % 24), 0);
        const minutes = Math.max(Math.floor((total / 60) % 60), 0);
        const seconds = Math.max(Math.floor(total % 60), 0);
        return { days: days, hours: hours, minutes: minutes, seconds: seconds };
    }

    private calculateTimestamp(
        lastBlockTimestamp: number,
        lastBlockHeight: number,
        startBlockHeight: number,
        blockTime: number
    ): number {
        return lastBlockTimestamp + ((startBlockHeight - lastBlockHeight) * blockTime);
    }

    private isElapsed(remainingTime: RemainingTime): boolean {
        return (
            remainingTime.days === 0 &&
            remainingTime.hours === 0 &&
            remainingTime.minutes === 0 &&
            remainingTime.seconds === 0
        );
    }
}
