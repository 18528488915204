import { X } from "lucide-react";
import { ChangeEvent, Component, createRef, Fragment, ReactNode, RefObject } from "react";
import { Link, Navigate } from "react-router-dom";
import { animateScroll, Link as SmoothScroll } from "react-scroll";
import logoBlue from "../../assets/images/rgb-logo-blue.svg";
import logoWhite from "../../assets/images/rgb-logo-white.svg";
import { CanvasCache } from "../cache/canvas_cache";
import { RotatingChevronDown } from "../elements/RotatingChevronDown";
import { WalletHandler, WalletState } from "../wallet/wallet_handler";
import { CANVAS_ID_KEY } from "../util/common";
import { ResolvablePromise } from "../util/helpers";
import { AlertProps } from "./alert";
import "./navbar.css";

interface NavBarProps {
    marginLeft?: string;
    redirect: boolean;
    floating?: boolean;
    alertCb?: (alert: AlertProps) => void;
}

interface NavBarState {
    walletState: WalletState;
    activeId: number|null;
    pendingId: number|null;
    subscribeStateChangedId: string|null;
    subscribeConnectOpenId: string|null;
    subscribeChainOpenId: string|null;
    authenticating: boolean;
    targetUrl: string|null;
    redirect: boolean;
    connectOpened: boolean;
    chainOpened: boolean;
    docsOpened: boolean;
    featuresOpened: boolean;
    userGuidesOpened: boolean;
    legalOpened: boolean;
    linksOpened: boolean;
}

export class NavBar extends Component<NavBarProps, NavBarState> {
    public state: NavBarState = {
        walletState: WalletState.LOADING,
        activeId: null,
        pendingId: null,
        subscribeStateChangedId: null,
        subscribeConnectOpenId: null,
        subscribeChainOpenId: null,
        authenticating: false,
        targetUrl: null,
        redirect: false,
        connectOpened: false,
        chainOpened: false,
        docsOpened: true,
        featuresOpened: true,
        userGuidesOpened: true,
        legalOpened: true,
        linksOpened: true,
    };
    private mobileMenuInputRef: RefObject<HTMLInputElement>;

    constructor(props: NavBarProps) {
        super(props);
        this.mobileMenuInputRef = createRef();
    }

    public async componentDidMount(): Promise<void> {
        const subscribeStateChangedId = WalletHandler.getInstance().subscribeStateChanged(async (walletState) => {
            this.setState((state) => ({ ...state, walletState: walletState }));

            if (this.state.authenticating) {
                switch (walletState) {
                    case WalletState.AUTHENTICATED: {
                        const state = await this.getLatestState();

                        if (state.targetUrl) {
                            this.setState((state) => ({
                                ...state,
                                authenticating: false,
                                redirect: true,
                            }));
                            break;
                        }

                        this.setState((state) => ({
                            ...state,
                            authenticating: false,
                            targetUrl: null,
                        }));
                        break;
                    }

                    case WalletState.UNAUTHENTICATED:
                        animateScroll.scrollToBottom();
                        this.notifyUnsubscribed();
                        this.setState((state) => ({
                            ...state,
                            authenticating: false,
                            targetUrl: null,
                        }));
                        break;

                    case WalletState.EMAIL_NOT_VERIFIED:
                        this.notifyEmailNotVerified();
                        this.setState((state) => ({
                            ...state,
                            authenticating: false,
                            targetUrl: null,
                        }));
                        break;

                    case WalletState.PENDING_AUTHENTICATION: {
                        const state = await this.getLatestState();

                        if (state.authenticating && state.targetUrl) {
                            await this.authenticate(state.targetUrl);
                        }

                        break;
                    }

                    case WalletState.NOT_CONNECTED:
                        await WalletHandler.getInstance().openConnect();
                        break;

                    case WalletState.UNSUPPORTED_NETWORK:
                        await WalletHandler.getInstance().openChain();
                        break;
                }
            }
        });

        const subscribeConnectOpenId = WalletHandler.getInstance().subscribeConnectOpen(async (open) => {
            if (!open) {
                const state = await this.getLatestState();

                if (state.connectOpened && state.walletState === WalletState.NOT_CONNECTED) {
                    this.setState((state) => ({ ...state, authenticating: false }));
                }

                this.setState((state) => ({ ...state, connectOpened: false }));
            }
        });

        const subscribeChainOpenId = WalletHandler.getInstance().subscribeChainOpen(async (open) => {
            if (!open) {
                const state = await this.getLatestState();

                if (state.chainOpened && state.walletState === WalletState.UNSUPPORTED_NETWORK) {
                    this.setState((state) => ({ ...state, authenticating: false }));
                }

                this.setState((state) => ({ ...state, chainOpened: false }));
            }
        });

        this.setState((state) => ({
            ...state,
            subscribeStateChangedId: subscribeStateChangedId,
            subscribeConnectOpenId: subscribeConnectOpenId,
            subscribeChainOpenId: subscribeChainOpenId,
        }));

        // Provides draw url for currently active canvas
        const active = await CanvasCache.getInstance().getActiveCanvas();

        if (active) {
            this.setState((state) => ({ ...state, activeId: active.canvasId }));
            return;
        }

        // Provides pending url for next canvas
        const pending = await CanvasCache.getInstance().getPendingCanvas();

        if (pending) {
            this.setState((state) => ({ ...state, pendingId: pending.canvasId }));
        }
    }

    public componentWillUnmount(): void {
        if (this.state.subscribeStateChangedId) {
            WalletHandler.getInstance().unsubscribeStateChanged(this.state.subscribeStateChangedId);
        }

        if (this.state.subscribeConnectOpenId) {
            WalletHandler.getInstance().unsubscribeConnectOpen(this.state.subscribeConnectOpenId);
        }

        if (this.state.subscribeChainOpenId) {
            WalletHandler.getInstance().unsubscribeChainOpen(this.state.subscribeChainOpenId);
        }

        document.body.style.overflowY = "";
    }

    public render(): ReactNode {
        if (this.state.redirect && this.state.targetUrl) {
            return <Navigate to={this.state.targetUrl} />;
        }

        return (
            <div className={"container" + ((this.props.floating ?? true) ? "" : " nav-bar-block")}>
                <div id="nav-bar-row">
                    <Link id="nav-logo" to="/" style={{ marginLeft: this.props.marginLeft ?? "0" }}>
                        <img alt="" src={logoBlue}/>
                    </Link>
                    {this.getMenu()}
                </div>
            </div>
        );
    }

    private getMenu(): ReactNode {
        const drawUrl = (this.state.activeId !== null) ? `/draw?${CANVAS_ID_KEY}=${this.state.activeId}` :
            (this.state.pendingId !== null) ? `/pending?${CANVAS_ID_KEY}=${this.state.pendingId}` : "/draw";
        return (
            <Fragment>
                {this.getDesktopMenu(drawUrl)}
                {this.getMobileMenu(drawUrl)}
            </Fragment>
        );
    }

    private getDesktopMenu(drawUrl: string): ReactNode {
        return (
            <nav id="desktop-menu">
                <ul>
                    <li><Link to="/"><p>Home</p></Link></li>
                    <li>{this.createLink(drawUrl, <p>Draw</p>)}</li>
                    <li>{this.createLink("/gallery", <p>Gallery</p>)}</li>
                    <li><Link to="/docs/" reloadDocument><p>Docs</p></Link></li>
                    <li>{this.createLink("/beta", <button>BETA</button>)}</li>
                </ul>
            </nav>
        );
    }

    private getMobileMenu(drawUrl: string): ReactNode {
        const mobileRowHeight = (1.25) + (0.375 * 2) + (0.25) + (0.07); // (1rem font size x 1.25 line height) + (0.375rem x 2 padding) + (0.25rem margin) + (0.07rem offset from somewhere)
        return (
            <Fragment>
                <input ref={this.mobileMenuInputRef} onChange={(event) => this.mobileMenuToggled(event)} type="checkbox" id="mobile-menu-input" />
                <label id="mobile-menu" htmlFor="mobile-menu-input">
                    <div id="mobile-menu-overlay"></div>
                    <nav id="sidebar-menu">
                        <div id="mobile-menu-header">
                            <Link id="mobile-menu-logo" to="/">
                                <img alt="" src={logoWhite}/>
                            </Link>
                            <div>
                                {this.createLink("/beta", <button>BETA</button>)}
                                <button onClick={() => this.hideMenu()}><X color="#dadde1" width="30" height="30"/></button>
                            </div>
                        </div>
                        <ul id="mobile-menu-content">
                            <li className="mobile-menu-item"><Link to="/">Home</Link></li>
                            <li className="mobile-menu-item">{this.createLink(drawUrl, <Fragment>Draw</Fragment>)}</li>
                            <li className="mobile-menu-item">{this.createLink("/gallery", <Fragment>Gallery</Fragment>)}</li>
                            <li className="mobile-menu-chevron">
                                <Link to="/docs/" reloadDocument>Docs</Link>
                                <RotatingChevronDown onClick={(isRotated) => this.setState((state) => ({ ...state, docsOpened: !isRotated }))} />
                            </li>
                            <li className="mobile-menu-collapsible" style={{ maxHeight: this.state.docsOpened ? (mobileRowHeight * 17) + "rem" : 0 }}>
                                <ul style={{ paddingLeft: "0.75rem" }}>
                                    <li className="mobile-menu-item"><Link to="/docs/welcome" reloadDocument>👋 Welcome to RGB</Link></li>
                                    <li className="mobile-menu-chevron">
                                        <Link to="/docs/category/features" reloadDocument>Features</Link>
                                        <RotatingChevronDown onClick={(isRotated) => this.setState((state) => ({ ...state, featuresOpened: !isRotated }))} />
                                    </li>
                                    <li className="mobile-menu-collapsible" style={{ maxHeight: this.state.featuresOpened ? (mobileRowHeight * 4) + "rem" : 0 }}>
                                        <ul style={{ paddingLeft: "0.75rem" }}>
                                            <li className="mobile-menu-item"><Link to="/docs/features/rgb-token" reloadDocument>RGB Token</Link></li>
                                            <li className="mobile-menu-item"><Link to="/docs/features/canvases" reloadDocument>Canvases</Link></li>
                                            <li className="mobile-menu-item"><Link to="/docs/features/timelapse-nfts" reloadDocument>Timelapse NFTs</Link></li>
                                            <li className="mobile-menu-item"><Link to="/docs/features/lottery" reloadDocument>Lottery</Link></li>
                                        </ul>
                                    </li>
                                    <li className="mobile-menu-chevron">
                                        <Link to="/docs/category/user-guides" reloadDocument>User Guides</Link>
                                        <RotatingChevronDown onClick={(isRotated) => this.setState((state) => ({ ...state, userGuidesOpened: !isRotated }))} />
                                    </li>
                                    <li className="mobile-menu-collapsible" style={{ maxHeight: this.state.userGuidesOpened ? (mobileRowHeight * 3) + "rem" : 0 }}>
                                        <ul style={{ paddingLeft: "0.75rem" }}>
                                            <li className="mobile-menu-item"><Link to="/docs/user-guides/adding-lisk-network" reloadDocument>Adding Lisk Network</Link></li>
                                            <li className="mobile-menu-item"><Link to="/docs/user-guides/bridging-lisk-network" reloadDocument>Bridging ETH to Lisk Network</Link></li>
                                            <li className="mobile-menu-item"><Link to="/docs/user-guides/rgb-token-purchase" reloadDocument>Purchasing RGB Tokens</Link></li>
                                        </ul>
                                    </li>
                                    <li className="mobile-menu-chevron">
                                        <Link to="/docs/category/legal" reloadDocument>Legal</Link>
                                        <RotatingChevronDown onClick={(isRotated) => this.setState((state) => ({ ...state, legalOpened: !isRotated }))} />
                                    </li>
                                    <li className="mobile-menu-collapsible" style={{ maxHeight: this.state.legalOpened ? (mobileRowHeight * 2) + "rem" : 0 }}>
                                        <ul style={{ paddingLeft: "0.75rem" }}>
                                            <li className="mobile-menu-item"><Link to="/docs/legal/terms-of-service" reloadDocument>Terms of Service</Link></li>
                                            <li className="mobile-menu-item"><Link to="/docs/legal/privacy-policy" reloadDocument>Privacy Policy</Link></li>
                                        </ul>
                                    </li>
                                    <li className="mobile-menu-chevron">
                                        <div>Links</div>
                                        <RotatingChevronDown onClick={(isRotated) => this.setState((state) => ({ ...state, linksOpened: !isRotated }))} />
                                    </li>
                                    <li className="mobile-menu-collapsible" style={{ maxHeight: this.state.linksOpened ? (mobileRowHeight * 3) + "rem" : 0 }}>
                                        <ul style={{ paddingLeft: "0.75rem" }}>
                                            <li className="mobile-menu-item"><Link to="https://x.com/rgblisk" reloadDocument>X</Link></li>
                                            <li className="mobile-menu-item"><Link to="https://rgblisk.medium.com" reloadDocument>Medium</Link></li>
                                            <li className="mobile-menu-item"><Link to="https://www.youtube.com/@rgblisk" reloadDocument>YouTube</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </label>
            </Fragment>
        );
    }

    private hideMenu(): void {
        if (this.mobileMenuInputRef.current?.checked) {
            this.mobileMenuInputRef.current.checked = false;
            document.body.style.overflowY = "";
        }
    }

    private createLink(url: string, children: ReactNode): ReactNode {
        switch (this.state.walletState) {
            case WalletState.AUTHENTICATED:
                return (
                    <Link id={"nav-bar-authenticated"} to={url}>{children}</Link>
                );

            case WalletState.PENDING_AUTHENTICATION:
                return (
                    <Link id={"nav-bar-pending-authentication"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              await this.authenticate(url);
                          }}
                    >{children}</Link>
                );

            case WalletState.NOT_CONNECTED:
                return (
                    <Link id={"nav-bar-not-connected"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              this.setState((state) => ({
                                  ...state,
                                  authenticating: true,
                                  connectOpened: true,
                                  targetUrl: url,
                              }));
                              await WalletHandler.getInstance().openConnect();
                          }}
                    >{children}</Link>
                );

            case WalletState.UNSUPPORTED_NETWORK:
                return (
                    <Link id={"nav-bar-unsupported-network"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              this.setState((state) => ({
                                  ...state,
                                  authenticating: true,
                                  chainOpened: true,
                                  targetUrl: url,
                              }));
                              await WalletHandler.getInstance().openChain();
                          }}
                    >{children}</Link>
                );

            case WalletState.EMAIL_NOT_VERIFIED:
                return (
                    <Link id={"nav-bar-email-not-verified"}
                          to={url}
                          onClick={async (event) => {
                              event.preventDefault();
                              await this.authenticate(url)
                          }}
                    >{children}</Link>
                );

            case WalletState.LOADING:
            case WalletState.AUTHENTICATING:
                return (
                    <Link id={"nav-bar-loading"}
                          to={url}
                          onClick={(event) => {
                              event.preventDefault();
                              this.notifyLoading();
                          }}
                    >{children}</Link>
                );

            case WalletState.UNAUTHENTICATED:
            default:
                if (this.props.redirect) {
                    return (
                        <Link id={"nav-bar-unauthenticated"}
                              to={url}
                              onClick={(event) => {
                                  event.preventDefault();
                                  window.location.href = "/";
                              }}
                        >{children}</Link>
                    );
                }

                return (
                    <SmoothScroll to="subscribe"
                                  onClick={() => {
                                      this.hideMenu();
                                      this.notifyUnsubscribed();
                                  }}
                                  smooth={true}
                                  offset={-70}
                                  duration={500}>
                        {children}
                    </SmoothScroll>
                );
        }
    }

    private notifyLoading(): void {
        this.displayAlert("Wallet loading, please wait...", "message", 2000);
    }

    private notifyEmailNotVerified(): void {
        this.displayAlert("Email not verified, please check emails for verification link", "error");
    }

    private notifyUnsubscribed(): void {
        this.displayAlert("Enter your email to apply for beta access", "warning");
    }

    private displayAlert(
        message: string,
        severity: "success" | "info" | "warning" | "error" | "message",
        autoHideDuration?: number
    ): void {
        if (!!this.props.alertCb) {
            this.props.alertCb({
                message: message,
                severity: severity,
                autoHideDuration: autoHideDuration,
            });
        }
    }

    private async authenticate(url: string): Promise<void> {
        if (!await WalletHandler.getInstance().isAuthenticated()) {
            try {
                this.setState((state) => ({
                    ...state,
                    authenticating: true,
                    targetUrl: url,
                }));
                await WalletHandler.getInstance().authenticate();
            } catch (error) {
                if (error instanceof Error && error.toString().includes("UserRejectedRequestError")) {
                    this.displayAlert("Please sign in to access beta", "error");
                } else {
                    this.displayAlert("Unknown error occurred", "error", 2000);
                }

                this.setState((state) => ({
                    ...state,
                    authenticating: false,
                    targetUrl: null,
                }));
            }
        } else {
            this.setState((state) => ({
                ...state,
                authenticating: false,
                targetUrl: url,
                redirect: true,
            }));
        }
    }

    private getLatestState(): Promise<NavBarState> {
        const latestState = new ResolvablePromise<NavBarState>();
        // Hack to get latest queued state
        this.setState((state) => {
            latestState.resolve(state);
            return state;
        });
        return latestState.promise();
    }

    private mobileMenuToggled(event: ChangeEvent<HTMLInputElement>): void {
        if (event.target.checked) {
            document.body.style.overflowY = "clip";
        } else {
            document.body.style.overflowY = "";
        }
    }
}
